import LayoutFullScreen from '@/components/Layout/LayoutFullScreen';
import Login from '@/components/Login/v2/Login';
import React from 'react';
import { isUnauthorizedRequest } from 'services/identity.service';

const Index = () => {
    return (
        <LayoutFullScreen className='bg-gradient-to-l md:from-basic-white' isHeaderHiddenForMob={false} isShowFooter={true} noSecondaryHeader={true}>
            <div className="3xl:max-w-screen-3xl mx-auto">
                <Login screenFor='sign-up-practitioner'  />
            </div>
        </LayoutFullScreen>
    );
}

export default Index;

export const getServerSideProps = async ({ req }) => {
    if (!isUnauthorizedRequest(req.cookies.AUTH)) {
        return { redirect: { destination: "/medicines", permanent: false } };
    }
    return {
        props: {}
    }
}